<template>
    <div class="d-flex align-items-center" style="min-height: 100vh;">
        <div class="col-sm-8 col-md-6 col-lg-4 mx-auto" style="min-width: 300px;">
            <div class="text-center mt-5 mb-1">
                <img src="@/assets/images/1.png" alt="Luma" width="200" class="mb-3">
            </div>
            <div class="card navbar-shadow">
                <div class="card-header text-center">
                    <div class="media">
                        <div class="media-body"><h4 class="card-title">Login</h4>
                            <p class="card-subtitle text-50">
                                Faça o Login para continuar
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <button type="button" class="btn mb-16pt btn-light btn-block" @click="redirectToGoogle"><span
                        class="fab fa-google mr-2"></span>
                        Login com Goolge
                    </button>
                    <div class="page-separator justify-content-center">
                        <div class="page-separator__text bg-white">ou</div>
                    </div>

                    <form novalidate="novalidate" id="form">

                        <div role="group" class="form-group">
                            <label for="email" class="d-block form-label">E-mail:</label>
                            <div class="bv-no-focus-ring">
                                <div role="group" class="input-group input-group-merge form-control-prepended">
                                    <input id="email" name="email" type="email" placeholder="E-mail"
                                           required="required" aria-required="true"
                                           v-model="form.email"
                                           class="form-control form-control-prepended">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fas fa-check"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div role="group" class="form-group">
                            <label for="password" class="d-block form-label">Senha:</label>
                            <div class="bv-no-focus-ring">
                                <div role="group" class="input-group input-group-merge form-control-prepended">
                                    <input id="password" type="password" placeholder="Senha" required="required"
                                           aria-required="true" name="password"
                                           v-model="form.password"
                                           class="form-control form-control-prepended">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fas fa-key"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <button type="submit" class="btn btn-primary btn-block" @click.prevent="onSubmit">Login
                            </button>
                        </div>
                    </form>
                </div>
                <div class="card-footer">
                    <div class="text-center text-black-50">
                        <router-link to="/esqueci-a-senha">
                            Esqueceu a Senha?
                        </router-link>
                    </div>
                    <hr>
                    <div class="text-center text-black-50">
                        Não tem cadastro?
                        <router-link to="/">
                            Criar conta
                        </router-link>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import http from "@/http/index";
import env from '@/env'
import store from "@/store";

export default {
    data() {
        return {
            form: {
                email: '',
                password: ''
            },
            title: 'Login'
        }
    },
    methods: {
        redirectToGoogle() {
            window.location.href = env.url + 'login/google?type=mentor&v=';
        },
        async onSubmit() {
            try {
                const response = await http.post('mentors/login', this.form);
                const result = await this.$store.dispatch("auth/initialize", response.data.token);
                if (result) {
                    const currentUser = store.state.auth.user;
                    if (currentUser.plan_id || !env.checkout) {
                        await this.$router.push('/home');
                    } else {
                        const token = localStorage.getItem('token')
                        await this.$router.push('/checkout?token=' + token);
                    }
                }
            } catch (e) {
                console.error('Mentors Login: ', e);
                this.$notifyError(e);
            }
        },
    }
}
</script>
